export class MessageInstanceEventContextError extends Error {
	constructor() {
		super('useMessageInstanceEvent must be used inside a Message template render component.');
	}
}

export class MessageInstanceEventUnknownActionTypeError extends Error {
	constructor() {
		super('Unknown action type');
	}
}
