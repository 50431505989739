import { usePostOfficeContext } from '@atlassian/post-office-context';
import { useMessageContext } from '@post-office/message-context';
import { usePlacementContext } from '@post-office/placement-context';
import { useRecommendationContext } from '@post-office/recommendation-context';
import {
	type AdditionalMetadata,
	type FrontendMessageInstanceEventWithoutRecipient,
	type PlacementIdsDependingOnChannel,
} from '@post-office/shared-contracts';
import { useCallback } from 'react';

import { MessageInstanceEventContextError } from './error';
import { MessageInstanceEventClient } from './message-instance-event-client';
import { type MessageInstanceClickedParams } from './types';

export type MessageInstanceEventHookScope = {
	messageInstanceViewed: (additionalMetadataFromFrontend?: AdditionalMetadata) => Promise<void>;
	messageInstanceClicked: (args: MessageInstanceClickedParams) => Promise<void>;
	messageInstanceDeliveredSuccessfully: (
		additionalMetadataFromFrontend?: AdditionalMetadata,
	) => Promise<void>;
};

/**
 * Exposes functions for updating message lifecycle
 */
export const useMessageInstanceEvent = (): MessageInstanceEventHookScope => {
	if (!globalThis.window.postOfficeMessageInstanceEventClient) {
		globalThis.window.postOfficeMessageInstanceEventClient = new MessageInstanceEventClient();
	}

	const client: MessageInstanceEventClient = globalThis.window.postOfficeMessageInstanceEventClient;

	const {
		messageInstanceId,
		messageTemplateId,
		transactionAccountId,
		triggerId: messageTriggerId,
		messageCreationType,
	} = useMessageContext();

	const { placementId } = usePlacementContext();
	const browserContext = usePostOfficeContext();
	const recommendationContext = useRecommendationContext();

	const messageInstanceDeliveredSuccessfully = useCallback(
		async (additionalMetadataFromFrontend: AdditionalMetadata) => {
			if (
				!messageInstanceId ||
				!messageTemplateId ||
				!placementId ||
				!messageCreationType ||
				!messageTriggerId
			) {
				// eslint-disable-next-line no-console
				console.error(new MessageInstanceEventContextError());
				return;
			}

			const event: FrontendMessageInstanceEventWithoutRecipient = {
				messageEventType: 'deliverySuccessful',
				messageInstanceId,
				messageTemplateId,
				deliveryChannel: 'in-app',
				placementId: placementId as PlacementIdsDependingOnChannel<'in-app'>,
				channelType: 'pull',
				context: {
					...browserContext,
					source: placementId,
					recommendation: {
						recommendationSessionId: recommendationContext.sessionId ?? '',
						recommendationEntityId: recommendationContext.entityId ?? '',
					},
				},
				additionalMetadataFromFrontend: transactionAccountId
					? {
							...additionalMetadataFromFrontend,
							transactionAccountId,
						}
					: additionalMetadataFromFrontend,
				messageTriggerId,
				messageCreationType,
				timestamp: Date.now(),
			};

			await client.sendMessageInstanceEvent(event);
		},
		[
			client,
			browserContext,
			messageInstanceId,
			messageTemplateId,
			placementId,
			messageCreationType,
			messageTriggerId,
			transactionAccountId,
			recommendationContext.entityId,
			recommendationContext.sessionId,
		],
	);

	const messageInstanceViewed = useCallback(
		async (additionalMetadataFromFrontend: AdditionalMetadata) => {
			if (
				!messageInstanceId ||
				!messageTemplateId ||
				!placementId ||
				!messageCreationType ||
				!messageTriggerId
			) {
				// eslint-disable-next-line no-console
				console.error(new MessageInstanceEventContextError());
				return;
			}

			const event: FrontendMessageInstanceEventWithoutRecipient = {
				messageEventType: 'viewed',
				messageInstanceId,
				messageTemplateId,
				deliveryChannel: 'in-app',
				placementId: placementId as PlacementIdsDependingOnChannel<'in-app'>,
				channelType: 'pull',
				context: {
					...browserContext,
					source: placementId,
					recommendation: {
						recommendationSessionId: recommendationContext.sessionId ?? '',
						recommendationEntityId: recommendationContext.entityId ?? '',
					},
				},
				additionalMetadataFromFrontend: transactionAccountId
					? {
							...additionalMetadataFromFrontend,
							transactionAccountId,
						}
					: additionalMetadataFromFrontend,
				messageTriggerId,
				messageCreationType,
				timestamp: Date.now(),
			};

			await client.sendMessageInstanceEvent(event);
		},
		[
			client,
			browserContext,
			messageInstanceId,
			messageTemplateId,
			placementId,
			messageCreationType,
			messageTriggerId,
			transactionAccountId,
			recommendationContext.entityId,
			recommendationContext.sessionId,
		],
	);

	const messageInstanceClicked = useCallback(
		async (params: MessageInstanceClickedParams) => {
			if (
				!messageInstanceId ||
				!messageTemplateId ||
				!placementId ||
				!messageCreationType ||
				!messageTriggerId
			) {
				// eslint-disable-next-line no-console
				console.error(new MessageInstanceEventContextError());
				return;
			}

			const clickEventPayload: FrontendMessageInstanceEventWithoutRecipient = {
				messageInstanceId,
				messageTemplateId,
				deliveryChannel: 'in-app',
				placementId: placementId as PlacementIdsDependingOnChannel<'in-app'>,
				channelType: 'pull',
				context: {
					...browserContext,
					source: placementId,
					recommendation: {
						recommendationSessionId: recommendationContext.sessionId ?? '',
						recommendationEntityId: recommendationContext.entityId ?? '',
					},
				},
				messageCreationType,
				messageTriggerId,
				...params,
				additionalMetadataFromFrontend: transactionAccountId
					? {
							...params.additionalMetadataFromFrontend,
							transactionAccountId,
						}
					: params.additionalMetadataFromFrontend,
				timestamp: Date.now(),
			};

			await client.sendMessageInstanceEvent(clickEventPayload);
		},
		[
			client,
			browserContext,
			messageInstanceId,
			messageTemplateId,
			placementId,
			messageCreationType,
			messageTriggerId,
			transactionAccountId,
			recommendationContext.entityId,
			recommendationContext.sessionId,
		],
	);

	return {
		messageInstanceDeliveredSuccessfully,
		messageInstanceViewed,
		messageInstanceClicked,
	};
};
