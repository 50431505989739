import { type MessageEventPostPayload } from './types';

const DEFAULT_PATH = `/gateway/api/post-office/api/v1/messages/lifecycle`;
const FLUSH_BATCH_EVENTS_TIMEOUT = 1000;
const MESSAGE_EVENTS_BATCH_SIZE = 250;

if (typeof fetch === 'undefined') {
	// If fetch is not supported, load the polyfill.
	require('whatwg-fetch');
}

export class MessageEventClient {
	private readonly endpointUrl: string = DEFAULT_PATH;
	private scheduledTimeout: number | null = null;
	private batchEvents: MessageEventPostPayload[] = [];

	public async sendEvent(event: MessageEventPostPayload) {
		const url = `${this.endpointUrl}`;
		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(event),
		});
		if (!response.ok) {
			// eslint-disable-next-line no-console
			console.error(`Failed to send event to analytics endpoint: ${response.statusText}`);
		}
	}

	batchSendEvent(event: MessageEventPostPayload) {
		this.batchEvents.push(event);
		if (this.scheduledTimeout === null) {
			this.scheduledTimeout = globalThis.window.setTimeout(
				this.flushBatchEvents.bind(this),
				FLUSH_BATCH_EVENTS_TIMEOUT,
			);
		}
	}

	async markAllAsReadEvent(messages: MessageEventPostPayload[]) {
		this.batchEvents.push(...messages);
		await this.flushBatchEvents();
	}

	private async sendMessageEvents(events: MessageEventPostPayload[]) {
		const url = `${this.endpointUrl}/bulk`;
		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(events),
		});
		if (!response.ok) {
			// eslint-disable-next-line no-console
			console.error(`Failed to send batch events to analytics endpoint: ${response.statusText}`);
		}
	}

	private async flushBatchEvents() {
		if (this.batchEvents.length === 0) {
			return;
		}

		if (this.batchEvents.length > MESSAGE_EVENTS_BATCH_SIZE) {
			const batch = this.batchEvents.slice(0, MESSAGE_EVENTS_BATCH_SIZE);
			await this.sendMessageEvents(batch);
			if (this.scheduledTimeout !== null) {
				globalThis.window.clearTimeout(this.scheduledTimeout);
			}
			this.batchEvents = this.batchEvents.slice(MESSAGE_EVENTS_BATCH_SIZE, this.batchEvents.length);
			this.scheduledTimeout = globalThis.window.setTimeout(
				this.flushBatchEvents.bind(this),
				FLUSH_BATCH_EVENTS_TIMEOUT,
			);
			return;
		}

		await this.sendMessageEvents(this.batchEvents);
		this.batchEvents = [];

		if (this.scheduledTimeout !== null) {
			globalThis.window.clearTimeout(this.scheduledTimeout);
			this.scheduledTimeout = null;
		}
	}
}
